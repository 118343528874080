import 'uno.css';
import { renderFloat } from "../sdk";
import { getScriptConfig } from "../utils";




renderFloat(getScriptConfig())


