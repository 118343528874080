import 'uno.css';
import { render as floatRender } from '../feedback-float'
import { render as panelRender } from '../feedback-panel'

import { TASCFeedbackRenderParams } from "../typing"
import { initStyle } from "../utils"



export function renderFloat(params: TASCFeedbackRenderParams){
  initStyle('float')
  floatRender(params)
}



export function renderPanel(params: TASCFeedbackRenderParams){
  panelRender(params)
}

