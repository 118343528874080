import pkg from '../package.json';
import type { IASCFeedbackPanelProps } from './typing';

const version = typeof process !== 'undefined' && process.env.DEF_VERSION || '1.0.56'

const { log } = console

log('utils version:', version, process.env.DEF_VERSION)


export const pkgName = pkg.name;

export function initStyle(type: 'float' | 'panel' = 'float'){
  const l = document.createElement('link');
  l.href=`https://${hostName}/code/npm/${pkgName}/${version}/${type}/index.css`
  l.rel = 'stylesheet'
  l.type= 'text/css'
  document.head.appendChild(l);
}



export const hostName = location.host.match('localhost') ? 'dev.g.alicdn.com' : document.querySelector('meta[name="env-cdn-host"]')?.getAttribute('content') || 'g.alicdn.com';
// export const ascDomainId = hostName.match(/dev\./) ? preDomainId : onlineDomainId
// export const ascPlatform = 'asc_portal'

export function getScriptConfig(): IASCFeedbackPanelProps{
  const scriptTagId = 'FEEDBACK_TRIGGER_SCRIPT'
  const scriptDom = document.getElementById(scriptTagId)
  const platform = scriptDom?.getAttribute('data-platform') || ''
  const domainId = scriptDom?.getAttribute('data-domain-id') || '-1'
  const useLagoModule = scriptDom?.getAttribute('data-use-lago-module') !== null
  const subTitleMdsKey = scriptDom?.getAttribute('data-subtitle-mds-key') as IASCFeedbackPanelProps['subTitleMdsKey']
  const enableCheckLogin = scriptDom?.getAttribute('data-enable-check-login') !== null
  const align = (scriptDom?.getAttribute('data-align') || 'top') as IASCFeedbackPanelProps['align']



  return {
    platform,
    useLagoModule,
    domainId: parseInt(domainId),
    subTitleMdsKey,
    enableCheckLogin,
    align,
  } 
}