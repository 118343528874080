import * as locale from '@alife/mcms_aidc-seller-center_asc-feedback-trigger';
import { TLocale } from './typing';

// asc 语言标为 id-id, 但mds中的语言标为 in-id

const effectiveLocale = {
  ...locale,
  ['id-id']: locale['in-id']
}

const ENV_LANG_META = document.querySelector('meta[name="env-lang"]')
const ENV_LANG = ENV_LANG_META && ENV_LANG_META.getAttribute('content')?.toLocaleLowerCase().replace('_','-') || 'en-us';

export default effectiveLocale[ENV_LANG] as TLocale