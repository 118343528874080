import { Button } from 'antd';
import { useMemo, useRef } from 'react';
import { createRoot } from 'react-dom/client';
import Draggable from 'react-draggable';
import { render as feedBackPanelRender } from '../feedback-panel';
import Locale from '../locale';
import type { FnRender, IASCFeedbackPanelProps } from '../typing';
import ProblemIcon from './icon/problem';

import Close from './icon/close';
import './index.scss';

const ROOT_ID = 'asc-feedback-float'

const STORAGE_KEY = 'feedback-float-y_20241203'
// const hostName = document.querySelector('meta[name="env-cdn-host"]')?.getAttribute('content') || 'g.alicdn.com';

interface IFloatProps extends IASCFeedbackPanelProps {
  btnText?: string
  onUnMount?: ()=>void
  parentDom: HTMLElement
}

function FeedbackFloat(params: IFloatProps){
  const { btnText = Locale?.['page.common.needhelp'] || 'Need Help', onUnMount, parentDom, align = 'top', ...props} = params

  const refDragY = useRef(0)
  const moveDomRef = useRef<HTMLDivElement>(null)

  const defaultY = Number(localStorage.getItem(STORAGE_KEY))|| 0

  const handleUnMount = () => {
    localStorage.removeItem(STORAGE_KEY)
    onUnMount && onUnMount()
  }

  const bounds = useMemo(()=>{
    return align === 'top' ? {
      top: 0,
      left: 0,
      right: 0,
      bottom: document.body.clientHeight - 150
    }: {
      top: -document.body.clientHeight + 200,
      left: 0,
      right: 0,
      bottom: 0
    }
  },[align])

  return <Draggable 
    offsetParent={parentDom || document.body}
    bounds={bounds}
    onStop={e=>{
      const transformY = moveDomRef.current?.style.transform.match(/translate\(\w+,(.*)px\)/)?.[1] || 0
      localStorage.setItem(STORAGE_KEY, String(transformY))
    }}  
    axis="y" 
    defaultPosition={{ x:0, y: defaultY }}
    >
    <div 
      ref={moveDomRef}
      data-spm="d_feedback"
      className={`fb-trigger-con fixed right-0 ${align === 'top' ? 'top-85px': 'bottom-85px'} bg-#fff rd-lt-2em rd-lb-2em rd-rt-0 rd-rb-0 shadow-xl`}
    >
      <span onClick={handleUnMount} className='close-icon hover-opacity-100 transition-300 absolute left--2px top--2px w-16px h-16px flex justify-center items-center bg-#2c2c2c hover-bg-#898989 block rd-50% z-2 color-#fff font-size-11px cursor-pointer'> <Close /> </span>
      <Button
        data-spm="d_feedback_float"
        onMouseDownCapture={(e)=>{
          refDragY.current = e.clientY
        }}
        onMouseUpCapture={(e)=>{
          // ensure trigger the feedback panel when click the button
          const val = e.clientY - refDragY.current

          if(Math.abs(val) < 5){
            feedBackPanelRender(props)
          }
        }}
        className='fb-trigger-btn rd-lt-2em rd-lb-2em rd-rt-0 rd-rb-0 aplus-auto-exp aplus-auto-clk' size='large' icon={<ProblemIcon />} type="text" shape="default">
        <span>{btnText}</span>
      </Button>
    </div>
  </Draggable>
}


const render: FnRender = (params: Omit<IFloatProps, 'parentDom'>) => {
  const { onCancel, ...rets } = params

  const domNode = document.createElement('div');
  domNode.id = ROOT_ID;
  domNode.style.width = '0px';
  domNode.style.height = '0px';
  domNode.style.position = 'fixed';
  domNode.style.right = '0';
  domNode.style.bottom = '0';
  domNode.style.zIndex = '10'
  document.body.appendChild(domNode);
  const root = domNode && (createRoot && createRoot(domNode) 
  //@ts-ignore
  || ReactDOM.unstable_createRoot(domNode)); ;

  const unmount = () => {
    root?.unmount();
    document.body.removeChild(domNode);
  }
  
  root.render(<FeedbackFloat {...rets} parentDom={domNode} onCancel={onCancel} onUnMount={unmount} />);

  
  return {
    unmount
  }
}

export {
  render
};

