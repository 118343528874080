import { createRoot } from 'react-dom/client';
import type { FnRender, IASCFeedbackPanelProps } from '../typing';
import ASCFeedbackPanel from './panel';



const render: FnRender = (params: IASCFeedbackPanelProps) => {

  const { onCancel, ...rets } = params

  const domNode = document.createElement('div');
  document.body.appendChild(domNode)
  const root = domNode && (createRoot && createRoot(domNode) 
  //@ts-ignore
  || ReactDOM.unstable_createRoot(domNode)); ;

  const handleCancel = () => {
    onCancel && onCancel();
    setTimeout(() => {
      root.unmount();
    },600)
  }

  const unmount = () => {
    root?.unmount();
    document.body.removeChild(domNode);
  }

  root.render(<ASCFeedbackPanel {...rets} onCancel={handleCancel} />);

  return {
    unmount
  }

}


export {
  ASCFeedbackPanel,
  render
};







