import { request } from '@alife/workstation-utils'

export function checkLogin(enableCheckLogin = true){

  const host = window.location.host
  if(host.includes('mc.lazada.com') || host.includes('localhost') || !enableCheckLogin){
    return false
  }
  
  return request({
    url: 'mtop.global.merchant.subaccount.otp.userinfo',
    method: 'GET',
  }).then(res=>{
    const { log } = console
    log('checkLogin', res)
    return !!res?.success
  }).catch(()=>{
    return false
  })
}