
import { useRequest } from 'ahooks';
import { Drawer, GetProps, Spin } from 'antd';
import { Suspense, useCallback, useMemo, useState } from 'react';
import locale from '../locale';
import { checkLogin } from '../service';
import { IASCFeedbackPanelProps } from '../typing';
import { LoginFeedbackForm, UnLoginFeedBackFormByLagoModule, UnLoginFeedbackForm } from './module-loader';



function ASCFeedbackPanel(props: IASCFeedbackPanelProps) {

  const { 
    domainId = 0, 
    platform = '',
    onCancel,
    onSuccess,
    useLagoModule,
    subTitleMdsKey,
    enableCheckLogin
  } = props;

  const [open, setOpen] = useState(true);

  const { data: isLogin, loading } = useRequest(checkLogin, {
    defaultParams: [enableCheckLogin],
  })

 

  const config = useMemo(()=> ({
    platform,
    domainId
  }),[ domainId, platform])

  const handleClose = useCallback(() => {
    setOpen(false);
    onCancel && onCancel();
  },[
    onCancel
  ])

  const handleSuccess = useCallback(() => {
    setOpen(false);
    onSuccess && onSuccess();
  },[onSuccess])

  const handleOnScreenshotStart = useCallback(() => {
    setOpen(false);
  },[])
  const handleOnScreenshotEnd = useCallback(() => {
    setOpen(true);
  },[])

  const moduleProps: GetProps<typeof UnLoginFeedbackForm> = useMemo(()=>({
    config,
    onSubmitted: handleSuccess,
    onCancel: handleClose,
    onScreenshotStart:handleOnScreenshotStart,
    onScreenshotEnd:handleOnScreenshotEnd,
    accountRequired: 'both',
    text: {
      subTitle: subTitleMdsKey ? locale[subTitleMdsKey] : locale['page.asc.onboarding.feedback.subtitle'],
      emailHolder: locale['page.asc.feedback.emailHolder'],
      phoneHolder: locale['page.asc.feedback.phoneHolder'],
      descHolder: locale['page.asc.feedback.descHolder'],
    }
  }),[
    config,
    handleSuccess,
    handleClose,
    handleOnScreenshotStart,
    handleOnScreenshotEnd,
    subTitleMdsKey
  ])

  const UnLoginCom = useMemo(()=> useLagoModule ? UnLoginFeedBackFormByLagoModule : UnLoginFeedbackForm,[useLagoModule])
  

  return <Drawer 
    title={locale['page.asc.feedback.title'] || 'Feedback Form'}
    onClose={handleClose} 
    open={open}
    width={650}
    destroyOnClose={false}
    styles={{
      body: {
        padding: '12px 16px'
      }
    }}
  >
    <Suspense fallback={null}>
      <Spin spinning={loading}>
        {
          isLogin ? <LoginFeedbackForm  /> :
          !loading && <UnLoginCom {...moduleProps} /> 
        }
    </Spin>
    </Suspense>
  </Drawer>
}

export default ASCFeedbackPanel;
