

// import type { FeedbackPanel } from '@alife/asc-feedback-unlogin';
import { dynamic } from '@route-resource-preload/react';
import { GetProps } from 'antd';
import { memo, useCallback } from 'react';

const FEEDBACK_UNLOGIN_FALLBACK_VERSION = '0.0.4';

export const UnLoginFeedbackForm = dynamic({
  loader: ()=> import('@alife/asc-feedback-unlogin'),
  submodule: 'FeedbackPanel'
})


export const LagoPkg = dynamic({
  loader: ()=> import('@alife/asc-pkg-loader'),
  submodule: 'LagoPkg'
})

export const UnLoginFeedBackFormByLagoModule = memo((
  props: GetProps<typeof UnLoginFeedbackForm>
) => {
  return <LagoPkg name="@alife/asc-feedback-unlogin" fallbackVersion={FEEDBACK_UNLOGIN_FALLBACK_VERSION}>
  {(App, { locale }) => {
    return (
      <App.FeedbackPanel {...props} locale={locale} />
    );
  }}
  </LagoPkg>
})


export const LoginFeedbackForm = memo((props)=> <LagoPkg name="@alife/asc-feedback" fallbackVersion={FEEDBACK_UNLOGIN_FALLBACK_VERSION}>
{(App, { locale }) => {

  const getDomainUrl = useCallback((): string => {
    const { pathname, search } = window?.location;
    return `/lu${!pathname || pathname === '/' ? '/homepage' : pathname}${search}`;
  },[])
  
  return (
    <App.default {...props} locale={locale} domainUrl={getDomainUrl()} />
  );
}}
</LagoPkg>)